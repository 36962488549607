<template>
  <div>
    <v-navigation-drawer class="grey darken-4" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app dark
    >
      <v-list rounded nav dense>
        <v-list-item-group active-class="primary--text text--accent-4">
          <template v-for="item in items">
            <v-list-item 
              v-if="!item.hasSubItems" 
              :to="item.to" 
              :key="'item-nav-' + item.title"
              link
            >
              <v-list-item-icon>
                <v-icon class="bounce-on-hover">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group 
              v-else 
              :prepend-icon="item.icon" 
              :key="'item-nav-group-' + item.title"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>
              <v-list-item 
                v-for="subItem in item.subItems"
                :key="'item-nav-group-' + item.title + '-sub-' + subItem.title" 
                :to="subItem.to"
                link
                style="padding-left: 24px;"
              >
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="grey darken-4" height="60px" :clipped-left="$vuetify.breakpoint.lgAndUp" app dark flat>
      <v-toolbar-title  class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <span class="logo-text ml-2">
        <img src="../../assets/ticket2pass.png" class="mt-2" />
        </span> -->
        <span class="hidden-sm-and-down">ADMIN WEBSHOP</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logOut">
        <v-icon dark>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      drawer: true,
      items: [
        { title: "Inicio", icon: "mdi-home", to: { name: 'Home' }, hasSubItems: false },
        { title: "Eventos", icon: "mdi-stadium", to: { name: 'Events' }, hasSubItems: false },
        {
          title: "Catálogos", icon: "mdi-folder", hasSubItems: true, subItems: [
            { title: "Tipos de eventos", icon: "mdi-calendar", to: { name: 'EventsTypes' } },

          ]
        },
        {
          title: "WebShop", icon: "mdi-web", hasSubItems: true, subItems: [
            { title: "Netpay", icon: "mdi-cash-lock", to: { name: 'Netpay' } },
            { title: "Temas", icon: "mdi-palette", to: { name: 'ThemeSettings' } },
          ]
        },
        { title: "Reportes", icon: "mdi-chart-bar", to: { name: 'Reports' }, hasSubItems: false },

      ],
    };
  },
  methods: {
    logOut() {
      this.$root.logOut()
    }
  }
};
</script>

<style scoped>
/* .v-list-item:hover .v-icon {
  animation: bounce 1s;
}
.v-list-group :hover {
  animation: bounce 1s;
} */
</style>
