<template>
    <v-app>
      <NavBar />
      <v-main>
          <v-slide-y-transition mode="out-in">
            <router-view class="views-container"/>
          </v-slide-y-transition>
      </v-main>
    </v-app>
  </template>
  
  <script>
import NavBar from '../components/layouts/NavBar.vue'
  import { EVENTMIXIN } from '@/mixins/eventsMixin'
  export default {
    name: "DashboardMain",
    components:{
      NavBar
    },
    mixins: [
      EVENTMIXIN
    ],
    mounted() {
      if(this.$store.state.auth.user.admin === 1) this.getCorps_Mixin();
      else this.LoadPlace_Mixin();
    }
  };
  </script>
  
  <style scoped>
  .views-container {
    height: calc(100vh - 60px) !important; 
    overflow-y: auto !important;
  }
  </style>